import { Form } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import {
  SidePanel,
  SidePanelBody,
  SidePanelFooter,
  SidePanelHeader,
} from "components/common/SidePanel";

import Button from "components/common/Button";
import { useTranslation } from "react-i18next";

const ComponentsSidePanel = ({
  handleSubmit,
  close,
  initalVal,
  newTask,
  handleClose,
}) => {
  const [modifyProperty, setModifyProperty] = useState(initalVal);
  const { t } = useTranslation();

  const handleChange = (e) => {
    setModifyProperty((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <Form
      onSubmit={(e) => {
        handleSubmit(e, modifyProperty);
      }}
    >
      <SidePanel>
        <SidePanelHeader>
          {initalVal?._id ? t("common.pages.modify") : t("common.pages.new")}{" "}
          {t("property_page.components")}
        </SidePanelHeader>
        <SidePanelBody>
          <div className="activity-input-container">
            <Form.Group>
              <Form.Label>{t("data_settings.component_code")}</Form.Label>
              <Form.Control
                name="u_component_abbreviation"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={
                  modifyProperty?.component_code || initalVal?.component_code
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.component_name")}</Form.Label>
              <Form.Control
                name="u_component_name"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.component_name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>System</Form.Label>
              <Form.Control
                name="u_system"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.u_system}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.interval_attendance")}</Form.Label>
              <Form.Control
                name="attendance_interval_value"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.attendance_interval}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Attendance Interval Unit </Form.Label>
              <Form.Control
                name="attendance_interval_unit"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.attendance_interval_unit}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.time_attendance")}</Form.Label>
              <Form.Control
                name="attendance_budget_time"
                type="Number"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.attendance_budget_time}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.text_attandance")}</Form.Label>
              <Form.Control
                name="attendance_text"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.textAttendance}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.interval_maint")} </Form.Label>
              <Form.Control
                name="maintenance_interval_value"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.maintenance_interval}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Maintenance Interval Unit </Form.Label>
              <Form.Control
                name="maintenance_interval_unit"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.maintenance_interval_unit}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.time_maint")}</Form.Label>
              <Form.Control
                name="maintenance_budget_time"
                type="number"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.maintenance_budget_time}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.text_maint")}</Form.Label>
              <Form.Control
                name="maintenance_text"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.textMaintenance}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.interval_clean")}</Form.Label>
              <Form.Control
                name="intervalClean"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.intervalClean}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.time_clean")}</Form.Label>
              <Form.Control
                name="cleaning_budget_time"
                type="number"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.timeClean}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("data_settings.text_clean")}</Form.Label>
              <Form.Control
                name="cleaning_text"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.textClean}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Attributes</Form.Label>
              <Form.Control
                name="attributes"
                type="text"
                required={true}
                placeholder={"-"}
                onChange={handleChange}
                value={modifyProperty?.attributes}
              />
            </Form.Group>
          </div>
        </SidePanelBody>
        <SidePanelFooter>
          <Button main type="submit">
            {t("planning_page.submit")}
          </Button>
          <Button
            secondary
            type="button"
            onClick={() => {
              handleClose();
              close();
            }}
          >
            {t("planning_page.cancel")}
          </Button>
        </SidePanelFooter>
      </SidePanel>
    </Form>
  );
};

export default ComponentsSidePanel;
